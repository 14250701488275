import React, { useState, useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextInput, TextInputType, Checkbox, Select } from '@design-system';
import { WLContext } from '@contexts';
import { translations } from 'translations';
import { useTranslation } from 'react-i18next';

interface NewDocumentFormFields {
  folder: string;
  name: string;
  description: string;
  selfSign: string;
  notification: string;
}

interface Errortype {
  type: string;
  name: 'folder' | 'name';
  message: string;
}

export interface NewDocumentFormProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;
  options?: Array<any>;
  onChange?: any;
  initialValues: any;
  formErorrs?: Array<Errortype>;
  features: any;
}

const schema = yup.object().shape({
  folder: yup.string().required(),
  name: yup.string().required(),
  description: yup.string().required(),
});

export const NewDocumentForm: React.FC<NewDocumentFormProps> = ({
  className,
  options,
  onChange,
  initialValues,
  formErorrs = [],
  features,
}) => {
  const { t } = useTranslation();
  const { color } = useContext(WLContext);
  const [checked, setChecked] = useState(() => initialValues.selfSign);
  const [checkedNotification, setCheckedNotification] = useState(
    () => initialValues.notification,
  );
  const {
    control,
    watch,
    getValues,
    setError,
    formState: { errors },
  } = useForm<NewDocumentFormFields>({
    resolver: yupResolver(schema) as any,
  });

  const handleCkeck = (val) => {
    setChecked(val);
    onChange({
      ...getValues(),
      selfSign: val,
    });
  };

  React.useEffect(() => {
    const subscription = watch((value) =>
      onChange({
        ...value,
        selfSign: checked,
        notification: checkedNotification,
      }),
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleCheckNotification = (val) => {
    setCheckedNotification(val);
    onChange({
      ...getValues(),
      notification: val,
    });
  };

  React.useEffect(() => {
    formErorrs.forEach(({ name, type, message }) =>
      setError(name, { type, message }),
    );
  }, [formErorrs]);

  return (
    <form className={className}>
      <div className="mb-4 text-left text-paragraph font-bold text-gray-500">
        {t(translations.DOCUMENT_PAGES.CREATE_DOCUMENT_TITLE)}
      </div>

      <div className="mb-4 flex flex-col">
        <Controller
          name="folder"
          defaultValue={initialValues.folder}
          control={control}
          render={({ name, ref, field }: any) => {
            return (
              <Select
                colorWL={color}
                {...field}
                name={name}
                className="w-full"
                value={options?.find((c) => c.value === initialValues.folder)}
                inputRef={ref}
                placeholder={t(translations.INPUTS.SELECT_FOLDER)}
                options={options}
              />
            );
          }}
        />
        {errors.folder && (
          <span className="ml-1 mt-1 text-red-300" role="alert">
            {errors.folder.message}
          </span>
        )}
      </div>

      <div className="mb-4 flex flex-col">
        <Controller
          name="name"
          defaultValue={initialValues.name}
          control={control}
          render={({ field }) => {
            return (
              <TextInput
                className="w-12/12 bg-white"
                id={field.name}
                name={field.name}
                defaultValue={field.value}
                onChange={field.onChange}
                type={TextInputType.text}
                isFullWidth
                required
                placeholder={t(translations.INPUTS.DOCUMENT_NAME)}
              />
            );
          }}
        />
        {errors.name && (
          <span className="ml-1 mt-1 text-red-300" role="alert">
            {errors.name.message}
          </span>
        )}
      </div>

      <div className="mb-4 flex flex-col">
        <Controller
          name="description"
          defaultValue={initialValues.description}
          control={control}
          render={({ field }) => {
            return (
              <TextInput
                className="w-12/12 bg-white"
                id={field.name}
                name={field.name}
                defaultValue={field.value}
                onChange={field.onChange}
                isFullWidth
                required
                type={TextInputType.text}
                placeholder={t(translations.COMMON.DESCRIPTION)}
              />
            );
          }}
        />
        {errors.description && (
          <span className="ml-1 mt-1 text-red-300" role="alert">
            {errors.description.message}
          </span>
        )}
      </div>

      <Checkbox
        defaultValue={initialValues.selfSign}
        onChange={(val) => handleCkeck(val)}
        label={t(translations.INPUTS.DOCUMENT_CHECKBOX)}
      />
      <Checkbox
        defaultValue={initialValues.notification}
        onChange={(val) => handleCheckNotification(val)}
        label={t(translations.INPUTS.DOCUMENT_NOTIFICATION)}
      />
      <Checkbox
        disabled={!features?.can_hide_certificate_pii?.enabled}
        defaultValue={initialValues.showCertificate}
        label={t(translations.INPUTS.SHOW_CERTIFICATE_PII)}
        onChange={() => {
          onChange({
            ...getValues(),
            showCertificate: !initialValues.showCertificate,
          });
        }}
      />
    </form>
  );
};
